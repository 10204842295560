/* eslint-disable */
import { graphql, StaticQuery } from 'gatsby';
import React from 'react';

import { DefaultLayout } from '../components/layout';
import { Map, Stage, ContactForm } from '../components/molecules';

const pageQuery = graphql`
  {
    allStagesJson(filter: { siteTitle: { eq: "Kontakt" } }) {
      edges {
        node {
          id
          siteTitle
          siteDescription
          title
          contentBlocks {
            id
            value
          }
          imageSrc {
            childImageSharp {
              fluid(maxWidth: 800, quality: 92) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
          imageAlt
        }
      }
    }
  }
`;

const Contact = ({ location }) => (
  <StaticQuery
    query={pageQuery}
    render={({ allStagesJson }) => {
      const { siteTitle, siteDescription, imageSrc, imageAlt, contentBlocks } = allStagesJson.edges[0].node;

      return (
        <DefaultLayout siteTitle={siteTitle} siteDescription={siteDescription} location={location}>
          <ContactForm />
        </DefaultLayout>
      );
    }}
  />
);

export default Contact;
